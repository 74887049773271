import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from 'react-icons/bs';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.css';

const SliderContent = (props) => {
  const [showModal, setShowModal] = useState(false);

  const showModalHandler = () => {
    setShowModal(!showModal);
  };

  const numOfSlide = props.slidesNum;

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <BsFillArrowRightCircleFill />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <BsFillArrowLeftCircleFill />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    speed: 400,
    slidesToShow: numOfSlide,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    centerPadding: 0,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    beforeChange: (curent, next) => setImageIndex(next),
  };

  return (
    <section className="slider">
      <h1 className="slider_title">{props.header}</h1>
      <div className={props.className}>
        <Slider {...settings}>
          {props.products.map((prod, idx) => {
            return (
              <div
                key={prod.id}
                className={idx === imageIndex ? 'slide activeSlide' : 'slide'}
              >
                <Link to={`/${prod.category}/${prod.item}`}>
                  <img
                    src={prod.imageSrc}
                    alt={prod.product}
                    onClick={showModalHandler}
                  />
                </Link>
                <div className="link_box">
                  <Link to={`/${prod.category}/${prod.item}`}>
                    <h2 className="slide_title">{prod.product}</h2>
                  </Link>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default SliderContent;
