import React, { useState, useEffect } from 'react';
import styles from './Questions.module.css';
import virtualKeyboard from '../../assets/images/Escribano/esc_keyboard_screen2.png';

// function to animate on view
function useOnScreen(options) {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);
    }, options);

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [ref, options]);

  return [setRef, visible];
}

const Questions = () => {
  const [setRef, visible] = useOnScreen({ rootMargin: '-10px' });

  return (
    <section className={styles.question}>
      {/* Feature Section */}
      <div className={styles.smart_box}>
        <h1 className={styles.smart_header}>Patricia Smart</h1>
        <p className={styles.smart_body}>
          Our educational search engine is designed specifically for students
          and educators, offering quick and intuitive access to the information
          you need.
          <br />
          <br />
          Experience the future of education today with Patricia Smart.
        </p>
      </div>

      {/* Whiteboard Section */}
      <div ref={setRef} className={styles.whiteboard}>
        <img src={virtualKeyboard} alt="virtual_keyboard_screen" />

        {visible ? (
          <div className={styles.questions}>
            <div className={styles.question_box}>
              <div className={styles.question1}>
                <span>Patricia,</span>
                <span>what</span>
                <span>are</span>
                <span>the</span>
                <span>vowel</span>
                <span>letters</span>
                <span>in</span>
                <span>alphabet?</span>
              </div>
              <div className={styles.answer1}>
                <span>The</span>
                <span>alphabet</span>
                <span>is</span>
                <span>made</span>
                <span>up</span>
                <span>of</span>
                <span>26</span>
                <span>letters,</span>
                <span>5</span>
                <span>of</span>
                <span>which</span>
                <span>are</span>
                <span>vowels</span>
                <span>(a, e, i, o, u).</span>
              </div>
            </div>
            {/* Question 2 */}
            <div className={styles.question_box}>
              <div className={styles.question2}>
                <span>Patricia,</span>
                <span>multiply</span>
                <span>5x6.</span>
              </div>
              <div className={styles.answer2}>
                <span>5x6=30</span>
              </div>
            </div>

            {/* Question 3 */}
            <div className={styles.question_box}>
              <div className={styles.question3}>
                <span>Patricia,</span>
                <span>who</span>
                <span>was</span>
                <span>the</span>
                <span>first</span>
                <span>president</span>
                <span>of</span>
                <span>the</span>
                <span>United</span>
                <span>States?</span>
              </div>
              <div className={styles.answer3}>
                <span>George</span>
                <span>Washington,</span>
                <span>from</span>
                <span>1732</span>
                <span>- 1799</span>
              </div>
            </div>

            {/* Question 4 */}
            <div className={styles.question_box}>
              <div className={styles.question4}>
                <span>Patricia,</span>
                <span>how</span>
                <span>many</span>
                <span>bones</span>
                <span>does</span>
                <span>the</span>
                <span>human</span>
                <span>body</span>
                <span>have?</span>
              </div>
              <div className={styles.answer4}>
                <span>The</span>
                <span>adult</span>
                <span>human</span>
                <span>skeleton</span>
                <span>is</span>
                <span>made</span>
                <span>up</span>
                <span>of</span>
                <span>206</span>
                <span>bones.</span>
              </div>
            </div>

            {/* Question 5 */}
            <div className={styles.question_box}>
              <div className={styles.question5}>
                <span>Patricia,</span>
                <span>what</span>
                <span>are</span>
                <span>the</span>
                <span>names</span>
                <span>of</span>
                <span>the</span>
                <span>planets</span>
                <span>in</span>
                <span>our</span>
                <span>solar</span>
                <span>system?</span>
              </div>
              <div className={styles.answer5}>
                <span>Mercury,</span>
                <span>Venus,</span>
                <span>Earth,</span>
                <span>Mars,</span>
                <span>Jupiter,</span>
                <span>Saturn,</span>
                <span>Uranus,</span>
                <span>Neptune.</span>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};

export default Questions;
