const libraries = [
  {
    id: 1,
    series: '1000 SERIES',
    product: 'Blade Tray C-4 Trim',
    link: 'https://nelsonadamsnaco.com/autocad-library/1000 SERIES.dwg',
  },
  {
    id: 2,
    series: '1500 SERIES',
    product: 'Blade Tray C-18 Trim',
    link: 'https://nelsonadamsnaco.com/autocad-library/BLADE TRAY C-18 TRIM.dxf',
  },
  {
    id: 3,
    series: '2000 SERIES',
    product: 'Box Tray C-4 Trim',
    link: 'https://nelsonadamsnaco.com/autocad-library/2000 SERIES.dwg',
  },
  {
    id: 4,
    series: '2500 SERIES',
    product: 'Box Tray C-18 Trim',
    link: 'https://nelsonadamsnaco.com/autocad-library/BOX TRAY C-18 TRIM.dxf',
  },
  {
    id: 5,
    series: 'FHS SERIES',
    product: 'Full Height Slider Series',
    link: 'https://nelsonadamsnaco.com/autocad-library/FHS1.dxf',
  },
  {
    id: 6,
    series: 'HSS SERIES',
    product: 'Horizontal Surface Mount Slider',
    link: 'https://nelsonadamsnaco.com/autocad-library/HSS.dxf',
  },
  {
    id: 7,
    series: 'TWS SERIES',
    product: 'Teaching Wall Sliders',
    link: 'https://nelsonadamsnaco.com/autocad-library/TEACHING WALLS SERIES.dwg',
  },
  {
    id: 8,
    series: 'VFM SERIES',
    product: 'Vertical Floor Mounted',
    link: 'https://nelsonadamsnaco.com/autocad-library/VFM SERIES.dwg',
  },
  {
    id: 9,
    series: 'VWM SERIES',
    product: 'Vertical Wall Mounted',
    link: 'https://nelsonadamsnaco.com/autocad-library/VWM SERIES.dwg',
  },
  {
    id: 10,
    series: 'FDW SERIES',
    product: 'Forbo Directly To Wall',
    link: 'https://nelsonadamsnaco.com/autocad-library/FDW.dxf',
  },
  {
    id: 11,
    series: 'TWP SERIES',
    product: 'Tackwall Panels',
    link: 'https://nelsonadamsnaco.com/autocad-library/TWP SERIES.dwg',
  },
  {
    id: 12,
    series: 'HDC SERIES',
    product: 'Hinged Display Cases',
    link: 'https://nelsonadamsnaco.com/autocad-library/HDC SERIES.dwg',
  },
  {
    id: 13,
    series: 'SDC SERIES',
    product: 'Sliding Display Cases',
    link: 'https://nelsonadamsnaco.com/autocad-library/SDC SERIES.dwg',
  },
];

const tnb = [
  {
    id: 1,
    series: 'DRM-3TB',
    product: 'Singles',
    link: 'https://nelsonadamsnaco.com/autocad-library/DRM-3TB.dxf',
  },
  {
    id: 2,
    series: 'DRM-6TB',
    product: 'Doubles',
    link: 'https://nelsonadamsnaco.com/autocad-library/DRM-6TB.dxf',
  },
  {
    id: 3,
    series: 'DRM-9TB',
    product: 'Triples',
    link: 'https://nelsonadamsnaco.com/autocad-library/DRM-9TB.dxf',
  },
  {
    id: 4,
    series: 'DRM-12TB',
    product: 'Quads',
    link: 'https://nelsonadamsnaco.com/autocad-library/DRM-12TB.dxf',
  },
  {
    id: 5,
    series: 'DSM-3TB',
    product: 'Singles',
    link: 'https://nelsonadamsnaco.com/autocad-library/DSM-3TB.dxf',
  },
  {
    id: 6,
    series: 'DSM-6TB',
    product: 'Doubles',
    link: 'https://nelsonadamsnaco.com/autocad-library/DSM-6TB.dxf',
  },
  {
    id: 7,
    series: 'DSM-9TB',
    product: 'Triples',
    link: 'https://nelsonadamsnaco.com/autocad-library/DSM-9TB.dxf',
  },
  {
    id: 8,
    series: 'DSM-12TB',
    product: 'Quads',
    link: 'https://nelsonadamsnaco.com/autocad-library/DSM-12TB.dxf',
  },
];

const instruction1 = [
  {
    id: 1,
    series: 'BOARDS',
    product: 'Box Tray Only',
    link: 'https://nelsonadamsnaco.com/installation-instructions/INSTALLATION INSTRUCTIONS- Box tray only.pdf',
  },
  {
    id: 2,
    series: 'BOARDS',
    product: 'Blade Tray and Box Tray',
    link: 'https://nelsonadamsnaco.com/installation-instructions/INSTALLATION INSTRUCTIONS- All Boards (Exact OD).pdf',
  },
  {
    id: 3,
    series: 'FHS SERIES',
    product: 'Full Height Slider Series',
    link: 'https://nelsonadamsnaco.com/installation-instructions/FHS_INSTALLATION_INSTRUCTIONS.pdf',
  },
  {
    id: 4,
    series: 'HSS SERIES',
    product: 'Horizontal Surface Mount Sliders',
    link: 'https://nelsonadamsnaco.com/installation-instructions/HSS_-_INSTALLATION_INSTRUCTIONS-Model.pdf',
  },
  {
    id: 5,
    series: 'TWS SERIES',
    product: 'Teaching Wall Sliders',
    link: 'https://nelsonadamsnaco.com/installation-instructions/TWS_-_TEACHING_WALLS_INSTALLATION_INSTRUCTIONS.pdf',
  },
  {
    id: 6,
    series: 'VFM SERIES',
    product: 'Vertical Floor Mounted',
    link: 'https://nelsonadamsnaco.com/installation-instructions/VWM_VFM_-_VERTICAL_SLIDER_INSTALL_INSTRUCTIONS.pdf',
  },
];
const instruction2 = [
  {
    id: 1,
    series: 'VWM SERIES',
    product: 'Vertical Wall Mounted',
    link: 'https://nelsonadamsnaco.com/installation-instructions/VWM_VFM_-_VERTICAL_SLIDER_INSTALL_INSTRUCTIONS.pdf',
  },
  {
    id: 2,
    series: 'FDW SERIES',
    product: 'Forbo Directly To Wall',
    link: 'https://nelsonadamsnaco.com/installation-instructions/FDW_INSTALLATION_INSTRUCTIONS.pdf',
  },
  {
    id: 3,
    series: 'TWP SERIES',
    product: 'Tackwall Panels',
    link: 'https://nelsonadamsnaco.com/installation-instructions/TWP_INSTALLATION_INSTRUCTIONS_WITH_NO_J-TRIM.pdf',
  },
  {
    id: 4,
    series: 'TWP-NT SERIES',
    product: 'Tackwall Panels',
    link: 'https://nelsonadamsnaco.com/installation-instructions/TWP_INSTALLATION_INSTRUCTIONS_WITH_NO_J-TRIM.pdf',
  },
  {
    id: 5,
    series: 'HDC SERIES',
    product: 'Hinged Display Cases',
    link: 'https://nelsonadamsnaco.com/installation-instructions/SDC_HDC_-_INSTALLATION_INSTRUCTIONS.pdf',
  },
  {
    id: 6,
    series: 'SDC SERIES',
    product: 'Sliding Display Cases',
    link: 'https://nelsonadamsnaco.com/installation-instructions/SDC_HDC_-_INSTALLATION_INSTRUCTIONS.pdf',
  },
  {
    id: 7,
    series: 'FHW SERIES',
    product: 'Installation with Divider Bar',
    link: 'https://nelsonadamsnaco.com/installation-instructions/FHW INSTALLATION INSTRUCTIONS -7-31-23.pdf',
  },
  {
    id: 8,
    series: 'Other Methods of installation',
    product: '',
    link: 'https://nelsonadamsnaco.com/installation-instructions/BLADE_TRAY_CLIPLESS_INSTALLATION_INSTRUCTIONS.pdf',
  },
];
const instruction3 = [
  {
    id: 1,
    series: 'DRM-3TB',
    product: 'Singles',
    link: 'https://nelsonadamsnaco.com/installation-instructions/DRM 3 INSTALL.pdf',
  },
  {
    id: 2,
    series: 'DRM-6TB',
    product: 'Doubles',
    link: 'https://nelsonadamsnaco.com/installation-instructions/DRM 6 INSTALL.pdf',
  },
  {
    id: 3,
    series: 'DRM-9TB',
    product: 'Triples',
    link: 'https://nelsonadamsnaco.com/installation-instructions/DRM 9 INSTALL.pdf',
  },
  {
    id: 4,
    series: 'DRM-12TB',
    product: 'Quads',
    link: 'https://nelsonadamsnaco.com/installation-instructions/DRM 12 INSTALL.pdf',
  },
  {
    id: 5,
    series: 'DSM-3TB',
    product: 'Singles',
    link: 'https://nelsonadamsnaco.com/installation-instructions/DSM 3 INSTALL.pdf',
  },
  {
    id: 6,
    series: 'DSM-6TB',
    product: 'Doubles',
    link: 'https://nelsonadamsnaco.com/installation-instructions/DSM 6 INSTALL.pdf',
  },
  {
    id: 7,
    series: 'DSM-9TB',
    product: 'Triples',
    link: 'https://nelsonadamsnaco.com/installation-instructions/DSM 9 INSTALL.pdf',
  },
  {
    id: 8,
    series: 'DSM-12TB',
    product: 'Quads',
    link: 'https://nelsonadamsnaco.com/installation-instructions/DSM 12 INSTALL.pdf',
  },
];

const prodData = [
  {
    id: 1,
    series: '1000 SERIES',
    product: 'Blade Tray C-4 Trim',
    link: 'https://nelsonadamsnaco.com/product-data/BLADE_TRAY_-_PRODUCT_DATA.pdf',//nelsonadamsnaco
  },
  {
    id: 2,
    series: '1500 SERIES',
    product: 'Blade Tray C-18 Trim',
    link: 'https://nelsonadamsnaco.com/product-data/BLADE_TRAY_WIDE_TRIM_-_PRODUCT_DATA.pdf',
  },
  {
    id: 3,
    series: '2000 SERIES',
    product: 'Box Tray C-4 Trim',
    link: 'https://nelsonadamsnaco.com/product-data/BOX_TRAY_-_PRODUCT_DATA.pdf',
  },
  {
    id: 4,
    series: '2500 SERIES',
    product: 'Box Tray C-18 Trim',
    link: 'https://nelsonadamsnaco.com/product-data/BOX_TRAY_WIDE_TRIM_-_PRODUCT_DATA.pdf',
  },
  {
    id: 5,
    series: 'FHS SERIES',
    product: 'Full Height Slider Series',
    link: 'https://nelsonadamsnaco.com/product-data/FULL_HEIGHT_SLIDERS_-_PRODUCT_DATA.pdf',
  },
  {
    id: 6,
    series: 'HSS SERIES',
    product: 'Horizontal Surface Mount Slider',
    link: 'https://nelsonadamsnaco.com/product-data/HSS_HORIZONTAL_SURFACEMOUNT_SLIDER-_PRODUCT_DATA.pdf',
  },
  {
    id: 7,
    series: 'TWS SERIES',
    product: 'Teaching Wall Sliders',
    link: 'https://nelsonadamsnaco.com/product-data/TEACHING_WALLS_-_PRODUCT_DATA.pdf',
  },
  {
    id: 8,
    series: 'TWS SERIES',
    product: 'Teaching Wall Sliders Custom',
    link: 'https://nelsonadamsnaco.com/product-data/TEACHING_WALLS_W-BOTTOM_ROLLERS_-_PRODUCT_DATA.pdf',
  },
  {
    id: 9,
    series: 'VFM SERIES',
    product: 'Vertical Floor Mounted',
    link: 'https://nelsonadamsnaco.com/product-data/VERTICAL_SLIDER_-_PRODUCT_DATA.pdf',
  },
  {
    id: 10,
    series: 'VWM SERIES',
    product: 'Vertical Wall Mounted',
    link: 'https://nelsonadamsnaco.com/product-data/VERTICAL_SLIDER_-_PRODUCT_DATA.pdf',
  },
  {
    id: 11,
    series: 'FDW SERIES',
    product: 'Forbo Directly To Wall',
    link: 'https://nelsonadamsnaco.com/product-data/FDW_-_PRODUCT_DATA.pdf',
  },
  {
    id: 12,
    series: 'TWP SERIES',
    product: 'Tackwall Panels',
    link: 'https://nelsonadamsnaco.com/product-data/TWP_-_PRODUCT_DATA_25f69d5a-bf1a-4cf1-8b54-85410c747180.pdf',
  },
  {
    id: 13,
    series: 'HDC SERIES',
    product: 'Hinged Display Cases',
    link: 'https://nelsonadamsnaco.com/product-data/HDC_-_PRODUCT_DATA.pdf',
  },
  {
    id: 14,
    series: 'SDC SERIES',
    product: 'Sliding Display Cases',
    link: 'https://nelsonadamsnaco.com/product-data/SDC_-_PRODUCT_DATA.pdf',
  },
  {
    id: 15,
    series: 'FHW SERIES',
    product: 'Full Height Writing Wall Visual Display',
    link: 'https://nelsonadamsnaco.com/product-data/FHW_-_PRODUCT_DATA.pdf',
  },
  {
    id: 16,
    series: 'TKS SERIES',
    product: 'Tackstrips Display Rails',
    link: 'https://nelsonadamsnaco.com/product-data/TKS_-_PRODUCT_DATA.pdf',
  },
];

const specifications = [
  {
    id: 1,
    series: '1000 SERIES',
    product: 'Blade Tray C-4 Trim',
    link: 'https://nelsonadamsnaco.com/specifications/10_11_00_VISUAL_DISPLAY_SURFACES_BLADE_TRAY.pdf',
  },
  {
    id: 2,
    series: '1500 SERIES',
    product: 'Blade Tray C-18 Trim',
    link: 'https://nelsonadamsnaco.com/specifications/10_11_00_VISUAL_DISPLAY_SURFACES_BLADE_WIDE_TRIM_SPECS.pdf',
  },
  {
    id: 3,
    series: '2000 SERIES',
    product: 'Box Tray C-4 Trim',
    link: 'https://nelsonadamsnaco.com/specifications/10_11_00_VISUAL_DISPLAY_SURFACES_BOX_TRAY.pdf',
  },
  {
    id: 4,
    series: '2500 SERIES',
    product: 'Box Tray C-18 Trim',
    link: 'https://nelsonadamsnaco.com/specifications/10_11_00_VISUAL_DISPLAY_SURFACES_BOX_WIDE_TRIM_SPECS.pdf',
  },
  {
    id: 5,
    series: 'FHS SERIES',
    product: 'Full Height Slider',
    link: 'https://nelsonadamsnaco.com/specifications/10_11_00_VISUAL_DISPLAY_SURACES_FULL_HEIGHT_SPECS.pdf',
  },
  {
    id: 6,
    series: 'HSS SERIES',
    product: 'Horizontal Surface Mount Slider',
    link: 'https://nelsonadamsnaco.com/specifications/10_11_01_HORIZONTAL_SLIDERS_SPECS.pdf',
  },
  {
    id: 7,
    series: 'TWS SERIES',
    product: 'Teaching Wall Sliders',
    link: 'https://nelsonadamsnaco.com/specifications/10_11_01_HORIZONTAL_SLIDERS_SPECS.pdf',
  },
  {
    id: 9,
    series: 'VFM SERIES',
    product: 'Vertical Floor Mounted',
    link: 'https://nelsonadamsnaco.com/specifications/10_11_02_VERTICAL_SLIDERS_SPECS.pdf',
  },
  {
    id: 10,
    series: 'VWM SERIES',
    product: 'Vertical Wall Mounted',
    link: 'https://nelsonadamsnaco.com/specifications/10_11_02_VERTICAL_SLIDERS_SPECS.pdf',
  },
  {
    id: 11,
    series: 'FDW SERIES',
    product: 'Forbo Directly To Wall',
    link: 'https://nelsonadamsnaco.com/specifications/Forbo_Colorex_Specifications.pdf',
  },
  {
    id: 12,
    series: 'TWP SERIES',
    product: 'Tackwall Panels',
    link: 'https://nelsonadamsnaco.com/specifications/00_92_50_TACKWALL_PANELS.pdf',
  },
  {
    id: 13,
    series: 'HDC SERIES',
    product: 'Hinged Display Cases',
    link: 'https://nelsonadamsnaco.com/specifications/10_12_10_DISPLAY_CASES.pdf',
  },
  {
    id: 14,
    series: 'SDC SERIES',
    product: 'Sliding Display Cases',
    link: 'https://nelsonadamsnaco.com/specifications/10_12_10_DISPLAY_CASES.pdf',
  },
  {
    id: 15,
    series: 'D-SERIES',
    product: 'Tables & Benches',
    link: 'https://nelsonadamsnaco.com/specifications/TABLES_BENCHES_SPECIFICATIONS.pdf',
  },
  {
    id: 16,
    series: 'D-SERIES',
    product: 'Tables & Benches Word Format',
    link: 'https://nelsonadamsnaco.com/specifications/T_B_SPECS_12640.docx',
  },
  {
    id: 17,
    series: 'FHW SERIES',
    product: 'Full Height Writing Walls',  //FHW SERIES - PRODUCT DATA.pdf
    link: 'https://nelsonadamsnaco.com/specifications/FHW SERIES - PRODUCT DATA.pdf',
  },
];

const colorCards = [
  {
    id: 1,
    series: 'NACO',
    product: 'e3 Procelain',
    link: 'https://nelsonadamsnaco.com/color-cards/NACO_e3_porcelain.pdf',
  },
  {
    id: 2,
    series: 'NACO',
    product: 'e3 Forbo',
    link: 'https://nelsonadamsnaco.com/color-cards/NACO_Forbo.pdf',
  },
  {
    id: 3,
    series: 'NACO',
    product: 'FR701 New',
    link: 'https://nelsonadamsnaco.com/color-cards/NACO_FR701_NEWEST.pdf',
  },
  {
    id: 4,
    series: 'NACO',
    product: 'Natural Cork Data',
    link: 'https://nelsonadamsnaco.com/color-cards/NACO_Natural_Cork_Data.pdf',
  },
  {
    id: 5,
    series: 'NACO',
    product: 'Powder Coat',
    link: 'https://nelsonadamsnaco.com/color-cards/NACO_POWDERCOAT.pdf',
  },
  {
    id: 6,
    series: 'NACO',
    product: 'Standard Vinyls',
    link: 'https://nelsonadamsnaco.com/color-cards/NACO_STANDARD_VINYLS.pdf',
  },
  {
    id: 7,
    series: 'NACO',
    product: 'Wilsonart',
    link: 'https://nelsonadamsnaco.com/color-cards/NACO Wilsonart Rev 04-10-19.pdf',
  },
];

export {
  libraries,
  tnb,
  instruction1,
  instruction2,
  instruction3,
  prodData,
  specifications,
  colorCards,
};
