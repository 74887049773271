import React from 'react';
import styles from './InstallationInstructions.module.css';

import { instruction1, instruction2, instruction3 } from './links';

const InstallationInstructions = () => {
  return (
    <section className={styles.instruction_section}>
      <h1 className={styles.instruction_title}>Installation Instructions</h1>

      <div className={styles.instruction_list_box}>
        <h2 className={styles.instruction_list_title}>
          Installation Instructions in PDF
        </h2>
        <div className={styles.instruction_list_grid}>
          <ul className={styles.instruction_list}>
            {instruction1.map((ins1) => (
              <li key={ins1.id}>
                <a target="_blank" rel="noopener noreferrer" href={ins1.link}>
                  <b>{ins1.series}</b> {ins1.product}
                </a>
              </li>
            ))}
          </ul>
          <ul className={styles.instruction_list}>
            {instruction2.map((ins2) => (
              <li key={ins2.id}>
                <a target="_blank" rel="noopener noreferrer" href={ins2.link}>
                  <b>{ins2.series}</b> {ins2.product}
                </a>
              </li>
            ))}
          </ul>
          <ul className={styles.instruction_list}>
            {instruction3.map((ins3) => (
              <li key={ins3.id}>
                <a target="_blank" rel="noopener noreferrer" href={ins3.link}>
                  <b>{ins3.series}</b> {ins3.product}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default InstallationInstructions;
