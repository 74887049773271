import React, { Component, useState, useEffect } from 'react'
import ChatBot from 'react-simple-chatbot'
import { ThemeProvider } from 'styled-components'
import WikipediaSearch from '../WikipediaSearch/WikipediaSearch'
import { FaMinus } from 'react-icons/fa'
import './Contenido.css';

import escribano from '../../assets/images/homepage/escribano.png';



var last_value="";

const theme = {
    margin:'auto',
    background: '#f5f8fb',
    headerBgColor: '#eb3449',
    headerFontColor: '#fff',
    headerFontSize: '23px',
    botBubbleColor: '#e1d8d973',
    botFontColor: '#a52a2a',
    userBubbleColor: '#e91e63',
    userFontColor: '#fff',
    minWidth: '80%',
    width:'100%',
}

const style_contact_title = {
  fontSize: '1.8em',
};
const style_contact_text = {
  fontSize: '1.5em',
};
const style_floating = {
  position: 'fixed',
  background: '#e91e638a',
  backgroundImage: 'url("/msje.png")',
  backgroundSize: 'cover',
  width: '60px',
  height: '55px',
  borderRadius: '20%',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
};
const style_letter_text = {
  fontSize: '1.7em',
  color:'#795548',
};
const closeIcon = (
    <div style={{ cursor: 'pointer' }}>
      <span>&times;</span>
    </div>
  );
const img_photo_msjes  = {

    borderRadius: '50% 50% 0',
    height: '47px',
    minWidth: '40px',
    padding: '0!important',

}
const styles = {
  bold: { fontWeight: 'bold' },
  italic: { fontStyle: 'italic' },
  red: { color: 'red' },
  green: { color: 'green' },
};

const length = 5; // Cambia esta longitud según tus necesidades
const array_time = Array.from({ length }, () => null);
var timeActual = new Date().toLocaleTimeString();


// const CustomComponentGrettings = (props) => {
//   // const currentTime = new Date().toLocaleTimeString();
//
//   // const nullArray = Array.from({ length }, () => null);
//   const previousValue =last_value;
//   // array_time.push(currentTime);
//   console.log(array_time);
//   console.log("Time: ",props.time);
//   return (
//     <div>
//      <div className="flex-container">
//        <div className="efROPc">
//           <img style={img_photo_msjes} src="/logo192.png"/>
//        </div>
//        <div className="component">
//           <p>  <font style={style_letter_text}> Hello {previousValue.value} , nice to meet you  </font></p>
//
//        </div>
//      </div>
//     </div>
//   );
// };
const CustomComponentGrettings = React.memo((props) => {
  const [shouldRender, setShouldRender] = useState(false);
  const previousValue =last_value;
  // array_time.push(currentTime);
  console.log(array_time);
  console.log("Time: ",props.time);

  useEffect(() => {
    const delay = 500; // Tiempo de retraso en milisegundos (2 segundos)

    const timeoutId = setTimeout(() => {
      setShouldRender(true);
    }, delay);

    return () => clearTimeout(timeoutId); // Limpiar el timeout si el componente se desmonta antes de que ocurra el retraso

  }, []);

  if (!shouldRender) {
    return null; // No renderizar el componente hasta que se cumpla el retraso
  }

  return (
    <div>
      <div className="flex-container">
        <div className="efROPc">
          <img style={img_photo_msjes} src="/logo192.png" alt="Logo" />
        </div>
        <div className="component">
          <p>
              <p>  <font style={style_letter_text}> Hello {previousValue.value} , nice to meet you  </font></p>
          </p>
        </div>
      </div>
    </div>
  );
});

// const CustomComponent = React.memo((props) => {
//   console.log("previousValue: ", last_value);
//   return (
//     <div>
//       <div className="flex-container">
//         <div className="efROPc">
//           <img style={img_photo_msjes} src="/logo192.png" alt="Logo" />
//         </div>
//         <div className="component">
//           <p>
//             <font style={style_letter_text}>{props.msje}</font>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// });
const CustomComponent = React.memo((props) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const delay = 1000; // Tiempo de retraso en milisegundos (2 segundos)

    const timeoutId = setTimeout(() => {
      setShouldRender(true);
    }, delay);

    return () => clearTimeout(timeoutId); // Limpiar el timeout si el componente se desmonta antes de que ocurra el retraso

  }, []);

  if (!shouldRender) {
    return null; // No renderizar el componente hasta que se cumpla el retraso
  }

  return (
    <div>
      <div className="flex-container">
        <div className="efROPc">
          <img style={img_photo_msjes} src="/logo192.png" alt="Logo" />
        </div>
        <div className="component">
          <p>
            <font style={style_letter_text}>{props.msje}</font>
          </p>
        </div>
      </div>
    </div>
  );
});

export default class Contenido extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
             <div style={{ width: '500px' }}>
                  <ChatBot

                      steps={[
                          {
                              id: "1",
                              // component: <CustomComponent msje = "Hello. I am a chatbot created by the nelson adams company. What's your name?" time = {new Date().toLocaleTimeString()} />,
                              message: "Hello. I am a chatbot created by the nelson adams company. What's your name?",
                              trigger: "2"
                          },
                          {
                              id: "2",
                              user: true,
                              validator: (value) => {
                                last_value={value}
                                timeActual = new Date().toLocaleTimeString();
                                  // if (/^[A-Z]{1}[a-z]{2,15}$/.test(value)) {
                                      return true;
                                  // }
                                  // else {
                                  //     return 'Please enter a valid name.';
                                  // }
                              },
                              trigger: "3"
                          },
                          {
                              id: "3",
                              // component: <CustomComponentGrettings time = {new Date().toLocaleTimeString()} />,
                              message: "Hello {previousValue} , nice to meet you  ",
                              trigger: "6A"
                          },
                          {
                              id: "4",
                              message: "Do you need anything from me?",
                              trigger: "5"
                          },
                          {
                              id: "5",
                              options: [
                                  {value: "y", label: "Yes", trigger: "6A"},
                                  {value: "n", label: "No", trigger: "6B"},
                              ]
                          },
                          {
                              id: "6A",
                              message: "Tell me what are you looking for...",
                              // component: <CustomComponent msje = "Tell me what are you looking for..." time = {timeActual} />,

                              trigger: "seleccion"
                          },
                          {
                              id: "6B",
                              message: "Thank you for visiting nelsonadamsnaco.com, See you later",
                              end: true
                          },
                          {
                              id: "seleccion",
                              options: [
                                  {value: "f", label: "List of products\n", trigger: "7A"},
                                  {value: "b", label: "Architects PDF List\n", trigger: "pdfArchitects"},
                                  {value: "c", label: "Contact list", trigger: "deparments"},
                              ]
                          },
                          {
                              id: "7A",
                              message: "Next I will show you the main categories of products with which we have",
                              trigger: "seleccionFront"
                          },
                          {
                              id: "deparments",
                              options: [
                                  {value: "Project Management team", label: "Project Management team", trigger: "contactProjectManagement"},
                                  {value: "Estimating team", label: "Estimating team", trigger: "contactEstimatingTeam"},
                                  {value: "Senior manager", label: "Senior manager", trigger: "contactSeniorManagement"},
                                  {value: "Legal department", label: "Legal department", trigger: "contactLegalDepartment"},
                                  {value: "Executive team", label: "Executive team", trigger: "contactExecutiveTeam"},

                              ]
                          },
                          {
                              id: "seleccionFront",
                              component: (<div className="ComponentList">      <a target="_blank" href="/visual-display-boards"> <font size="3" color="brown">Visual Display Boards </font> </a>
                                              <br /> <a target="_blank" href="/cafeteria-tables-and-benches">  <font size="3" color="brown">Cafeteria Tables and Benches </font></a>
                                              <br /> <a target="_blank" href="/teaching-walls"><font size="3" color="brown">Teaching Walls</font></a>
                                              <br /> <a target="_blank" href="/display-cases"><font size="3" color="brown">Display Cases</font></a>
                                              <br /> <a target="_blank" href="/wall-systems"><font size="3" color="brown">Wall Systems</font></a>
                              </div>
                            ),trigger:"preguntaVuelta",
                              // options: [
                              //     {value: "Angular_(framework)", label: "Visual Display Boards", trigger: "9"},
                              //     {value: "React", label: "Cafeteria Tables and Benches", trigger: "9"},
                              //     {value: "Vue.js", label: "Teaching Walls", trigger: "9"},
                              //     {value: "Vue.js", label: "Display Cases", trigger: "9"},
                              //     {value: "Vue.js", label: "Wall Systems", trigger: "9"},
                              // ]
                          },
                          {
                              id: "pdfArchitects",
                              component: (<div className="ComponentList">      <a target="_blank" href="/autocad-library"> <font size="3" color="brown">AutoCad Library </font> </a>
                                              <br /> <a target="_blank" href="/installation-instructions">  <font size="3" color="brown">Installation Instructions </font></a>
                                              <br /> <a target="_blank" href="/product-data"><font size="3" color="brown">Product Data</font></a>
                                              <br /> <a target="_blank" href="/specifications"><font size="3" color="brown">Specifications</font></a>
                                              <br /> <a target="_blank" href="/color-cards"><font size="3" color="brown">Color Cards</font></a>
                              </div>



                            ),trigger:"preguntaVuelta",
                              // options: [
                              //     { component: (<div> <a href="https://www.google.com.ar/">Ir a Google</a> </div> ),trigger:"9", label: "AutoCAD Library", trigger: "9"},
                              //     {value: "Laravel", label: "Installation Instructions", trigger: "9"},
                              //     {value: ".NET_Core", label: "Product Data", trigger: "9"},
                              //     {value: ".NET_Core", label: "Specifications", trigger: "9"},
                              //     {value: ".NET_Core", label: "Color Cards", trigger: "9"},
                              // ]
                          },
                          {
                              id: "contactProjectManagement",
                              component: (<div className="ComponentList">
                                              <font style={style_contact_title} ><b>Tel:</b> 909-383-0819 </font><br />
                                              <br />
                                              <font style={style_contact_text}><b> Director of project Management</b> </font><br />
                                              <font style={style_contact_text}> Sayra Mojica </font><br />
                                              <font style={style_contact_text}> sayra@nelsonadamsnaco.com  </font><br />
                                              <font style={style_contact_text}><b> Ext.</b> 115  </font><br />
                                              <br />
                                              <font style={style_contact_text}><b> Project manager</b> </font><br />
                                              <font style={style_contact_text}> Magali Santos</font><br />
                                              <font style={style_contact_text}> magali@nelsonadamsnaco.com  </font><br />
                                              <font style={style_contact_text}><b> Ext.</b> 111  </font><br />
                                              <br />
                                              <font style={style_contact_text}><b> Project manager</b> </font><br />
                                              <font style={style_contact_text}> Grecia Ocampo </font><br />
                                              <font style={style_contact_text}> grecia@nelsonadamsnaco.com </font><br />
                                              <font style={style_contact_text}><b> Ext.</b> 201  </font><br />
                                              <br />
                                              <font style={style_contact_text}><b> Project manager</b> </font><br />
                                              <font style={style_contact_text}> Jonathan Castro </font><br />
                                              <font style={style_contact_text}> jonathan@nelsonadamsnaco.com </font><br />
                                              <font style={style_contact_text}><b> Ext.</b> 204  </font><br />
                                              <br />

                              </div>
                            ),  trigger: "preguntaVuelta",

                          },
                          {
                              id: "contactEstimatingTeam",
                              component: (<div className="ComponentList">
                                              <font style={style_contact_title}><b>Tel:</b> 909-383-0819 </font><br />
                                              <br />
                                              <font style={style_contact_text}><b> Sales manager</b> </font><br />
                                              <font style={style_contact_text}> Arely Hernandez </font><br />
                                              <font style={style_contact_text}> arely@nelsonadamsnaco.com </font><br />
                                              <font style={style_contact_text}><b> Ext.</b> 112  </font><br />
                                              <br />
                                              <font style={style_contact_text}><b> Contract negotiator</b> </font><br />
                                              <font style={style_contact_text}> Yoanna Herrera </font><br />
                                              <font style={style_contact_text}> yoanna@nelsonadamsnaco.com </font><br />
                                              <font style={style_contact_text}><b> Ext.</b> 105  </font><br />
                                              <br />
                                              <font style={style_contact_text}><b> Estimator</b> </font><br />
                                              <font style={style_contact_text}> Hector Millan </font><br />
                                              <font style={style_contact_text}> hector@nelsonadamsnaco.com </font><br />
                                              <font style={style_contact_text}><b> Ext.</b> 117  </font><br />
                                              <br />
                                              <font style={style_contact_text}><b> Filtering</b> </font><br />
                                              <font style={style_contact_text}> Melanie Mojica </font><br />
                                              <font style={style_contact_text}> estimating@nelsonadamsnaco.com </font><br />
                                              <font style={style_contact_text}><b> Ext.</b> 116  </font><br />
                                              <br />

                              </div>
                            ),  trigger: "preguntaVuelta",

                          },
                          {
                              id: "contactSeniorManagement",
                              component: (<div className="ComponentList">
                                              <font style={style_contact_title}><b>Tel:</b> 909-383-0819 </font><br />
                                              <br />
                                              <font style={style_contact_text}><b> Senior manager</b> </font><br />
                                              <font style={style_contact_text}> Albert Cardenas </font><br />
                                              <font style={style_contact_text}> albert@nelsonadamsnaco.com</font><br />
                                              <font style={style_contact_text}><b> Ext.</b> 113  </font><br />
                                              <br />
                              </div>
                            ),  trigger: "preguntaVuelta",

                          },
                          {
                              id: "contactLegalDepartment",
                              component: (<div className="ComponentList">
                                              <font style={style_contact_title}><b>Tel:</b> 909-383-0819 </font><br />
                                              <br />
                                              <font style={style_contact_text}><b> Contracts</b> </font><br />
                                              <font style={style_contact_text}> Kelvin Chavez </font><br />
                                              <font style={style_contact_text}> kelvin@nelsonadamsnaco.com</font><br />
                                              <font style={style_contact_text}><b> Ext.</b> 106  </font><br />
                                              <br />
                              </div>
                            ),  trigger: "preguntaVuelta",

                          },
                          {
                              id: "contactExecutiveTeam",
                              component: (<div className="ComponentList">
                                              <font style={style_contact_title}><b>Tel:</b> 909-383-0819 </font><br />
                                              <br />
                                              <font style={style_contact_text}><b> President</b> </font><br />
                                              <font style={style_contact_text}> Rafael Rangel </font><br />
                                              <font style={style_contact_text}> rafaelrangel@nelsonadamsnaco.com</font><br />
                                              <br />
                                              <font style={style_contact_text}><b> General Manager</b> </font><br />
                                              <font style={style_contact_text}> General Manager </font><br />
                                              <font style={style_contact_text}> pflores@nelsonadamsnaco.com</font><br />

                              </div>
                            ),  trigger: "preguntaVuelta",

                          },
                          {
                              id: "9",
                              component: <WikipediaSearch />,
                              asMessage: true,
                              trigger: "preguntaVuelta"
                          },
                          {
                              id: "preguntaVuelta",
                              message: "Do you need to know anything else?",
                              trigger: "respuestaVuelta",
                          },
                          {
                              id: "respuestaVuelta",
                              options: [
                                  {value: "y", label: "Yes", trigger: "6A"},
                                  {value: "n", label: "No", trigger: "6B"},
                              ],
                          }
                      ]}
                      bubbleStyle={{ maxWidth: '80%',fontSize: '18px' }}
                      floating={true}
                      headerTitle="Nelson Adams Naco"
                      floatingStyle={style_floating}
                      botAvatar="/logo192.png"
                      botDelay="1200"
                      userDelay="1200"

                      botAvatarStyle={{
                        // Estilos para el avatar del bot
                        width: '60px',
                        height: '60px',
                        borderRadius: '50%',
                        backgroundColor: '#4caf50',
                        display: 'flex',
                        height: '100vh', /* Ajusta la altura del contenedor al 100% del viewport */
                        justifyContent: 'center', /* Centra horizontalmente */
                        alignItems: 'center', /* Centra verticalmente */

                      }}
                    //  headerComponent={closeIcon} // Usar el icono personalizado para cerrar
                  />
              </div>
            </ThemeProvider>
        )
    }
}
