import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import FeatureEscribano from './FeatureEscribano';
import Butterfly from './Butterfly';
import styles from './EscribanoPage.module.css';

// Animation
import Aos from 'aos';
import 'aos/dist/aos.css';

// Images
import escVideo from '../../assets/images/Escribano/escribano_vid.mp4';
import escLogo from '../../assets/images/Escribano/esc_logo.png';

const EscribanoPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1500, once: 'true' }, []);
  });
  return (
    <section className={styles.section_escribano}>
      <section className={styles.hero_section}>
        <video src={escVideo} loop controls playsInline></video>
      </section>

      <section className={styles.main_features}>
        {/* Escribano Header */}
        <div className={styles.esc_header_section}>
          <div
            className={styles.esc_header_logo}
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <img src={escLogo} alt="escribano_logo" />
          </div>
          <h1
            className={styles.esc_header_primary}
            data-aos="fade-up"
            data-aos-delay="500"
          >
            Escribano
          </h1>
          <h2
            className={styles.esc_header_secondary}
            data-aos="fade-up"
            data-aos-delay="700"
          >
            Evolving The World of Education
          </h2>
        </div>

        {/* Patricia Section */}
        <div className={styles.patricia_section}>
          <div className={styles.patricia_header_section}>
            <h2
              className={styles.patricia_header}
              data-aos="fade-right"
              data-aos-delay="500"
            >
              Reinventing School Technology With Patricia. Our Educational
              Virtual Assistant!
            </h2>
            <Link
              to="/patricia"
              className={styles.patricia_button}
              data-aos="fade-right"
              data-aos-delay="500"
            >
              Meet Patricia
            </Link>
          </div>
          <div
            className={styles.patricia_description}
            data-aos="fade-left"
            data-aos-delay="1500"
          >
            Escribano is an intelligent board that comes integrated with an
            Educational virtual assistant. Our system allows the user to easily
            manage a face-to-face or virtual presentation using a simple
            interface with voice recognition technology. Allowing them to teach
            a hands-free class.
          </div>
        </div>
        <Butterfly />
        <FeatureEscribano />
      </section>
    </section>
  );
};

export default EscribanoPage;
