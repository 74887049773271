import React from 'react';
import styles from './HeroBody.module.css';
import ModalAvail from './ModalAvail';

const HeroBody = (props) => {
  return (
    <div className={styles.product_body_box}>
      <h2 className={styles.product_body_title}>{props.title}</h2>
      <ul className={styles.product_body_description}>{props.children}</ul>
      <ModalAvail
        indicators={props.indicators}
        controls={props.controls}
        availImages={props.availImages}
      ></ModalAvail>
    </div>
  );
};

export default HeroBody;
