import React from 'react';
import styles from './ContactCard.module.css';
import { MdEmail } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';


const style_name = {
  fontSize: '2.5em',
};
const style_title = {
  fontSize: '1.9em',
};
const style_email = {
  fontSize: '0.9em',
};
const style_extension = {
  fontSize: '1.7em',
};


const ContactCard = (props) => {
  return (
    <div className={styles.contact_card}>
      <font  style={style_name} className={styles.cards_name}>{props.name}</font>
      <font  style={style_title} className={styles.cards_title}>{props.title}</font>
      <font  style={style_title} className={styles.cards_email_box}>
        <MdEmail />
        <a  style={style_email} href={`mailto:${props.email}`} className={styles.cards_email}>
          {props.email}
        </a>
      </font>
      {props.extNumber ? (
        <font  style={style_extension}  className={styles.cards_extension}>
          <BsFillTelephoneFill />
          {props.extNumber}
        </font>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ContactCard;
