import React from 'react';
import SliderContent from '../../../components/ProductsSlider/SliderContent';

import fdw_main from '../../../assets/images/TackwallSystems/FDW/fdw_main.png';
import twp_main from '../../../assets/images/TackwallSystems/TWP/twp_main.png';
import mw_main from '../../../assets/images/TackwallSystems/MarkerWall/mw_main.png';

const TackwallSystemPage = () => {
  const tables = [
    {
      id: 1,
      product: 'Forbo Directly to Wall',
      imageSrc: fdw_main,
      category: 'wall-systems',
      item: 'forbo-directly-to-wall',
    },
    {
      id: 2,
      product: 'Tackwall Panels',
      imageSrc: twp_main,
      category: 'wall-systems',
      item: 'tackwall-panels',
    },
    {
      id: 3,
      product: 'Marker Wall',
      imageSrc: mw_main,
      category: 'wall-systems',
      item: 'marker-wall',
    },
  ];
  return (
    <SliderContent
      header="Wall Systems"
      products={tables}
      slidesNum={2.99}
      className="wall-system"
    />
  );
};

export default TackwallSystemPage;
