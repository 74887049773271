import React from 'react';
import styles from './Joints.module.css';

import dividerBar from '../../assets/images/VisualBoards/Markerboards/divider_bar.jpg';
import hBar from '../../assets/images/VisualBoards/Markerboards/h_bar.jpg';
import splineJoint from '../../assets/images/VisualBoards/Markerboards/spline_joint.jpg';

const Joints = () => {
  return (
    <div className={styles.joints_box} data-aos="slide-up">
      <h3 className={styles.customize_joints_title}>
        Joints for boards over 16' in length
      </h3>
      <div className={styles.customize_joints_options_box}>
        <div className={styles.customize_joints_image}>
          <img src={splineJoint} alt="Spline_Joint" />
          <h4>Spline Joint</h4>
        </div>
        <div className={styles.customize_joints_image}>
          <img src={hBar} alt="H_Bar" />
          <h4>H-Bar</h4>
        </div>
        <div className={styles.customize_joints_image}>
          <img src={dividerBar} alt="Divider_bar" />
          <h4>Divider Bar</h4>
        </div>
      </div>
    </div>
  );
};

export default Joints;
