import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import styles from './AboutPage.module.css';
import { IoLocationSharp } from 'react-icons/io5';
import { project } from './projectList.js';

import Aos from 'aos';
import 'aos/dist/aos.css';

const AboutPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000, once: 'true' }, []);
  });

  return (
    <section className={styles.about_section}>
      {/* Projects Section */}
      <section className={styles.projects_body}>
        <h1
          className={styles.projects_title}
          data-aos="fade-down"
          data-aos-delay="500"
        >
          Projects
        </h1>
        <section
          className={styles.projects_gallery}
          data-aos="fade-up"
          data-aos-delay="1200"
        >
          {project.map((proj) => (
            <div key={proj.id} className={styles.projects_gallery_item}>
              <div className={styles.gallery_image}>
                <img src={proj.image} alt={proj.alt} />
              </div>
              <h3 className={styles.item_title}>{proj.title}</h3>
              <h4 className={styles.item_location}>
                <IoLocationSharp /> {proj.location}
              </h4>
            </div>
          ))}
        </section>
      </section>

      {/* Mission Section */}
      <section className={styles.mission_box}>
        <div className={styles.years_box}>
          <CountUp
            className={styles.years_number}
            enableScrollSpy={true}
            scrollSpyDelay={1500}
            end={70}
            duration={5}
          />
          <div className={styles.years_text}>
            years of building and remodelling experience
          </div>
        </div>
        <div
          className={styles.mission_body}
          data-aos="fade-down-left"
          data-aos-delay="1500"
        >
          <p>
            Since its start in 1953, Nelson Adams continues to maintain
            impeccable quality and customer service with our clients all over
            the United States. We take pride in our work ensuring not only that
            all our products are functional but{' '}
            <span className={styles.environment_tag}>
              environmentally friendly
            </span>{' '}
            as well.
          </p>
          <p>
            We take pride in our work and we continue a tradition that started
            in 1953. Since then we reinvented our processes, quality and the day
            to day operation but culture and family values stayed the same.
          </p>
        </div>
      </section>
    </section>
  );
};

export default AboutPage;
