import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BsFillCaretDownFill } from 'react-icons/bs';
import './Header.css';

import Offcanvas from 'react-bootstrap/Offcanvas';

import companyLogo from '../assets/images/company_logo_big.jpg';

const Header = () => {
  const location = useLocation();

  // states
  const [show, setShow] = useState(false);
  const [menuWhite, setMenuWhite] = useState(true);
  const [showProducts, setShowProducts] = useState(false);
  const [showArchitect, setShowArchitect] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShowProducts(false);
    setShowArchitect(false);
  };

  const handleShow = () => setShow(true);

  // Our Products Dropdown
  const handleShowProducts = () => {
    if (showProducts === false) {
      setShowProducts(true);
      setShowArchitect(false);
    } else {
      setShowProducts(false);
    }
  };

  // Architects Dropdown
  const handleShowArchitect = () => {
    if (showArchitect === false) {
      setShowArchitect(true);
      setShowProducts(false);
    } else {
      setShowArchitect(false);
    }
  };

  // Change Menu box color depending on page <path></path>
  useEffect(() => {
    if (
      location.pathname === '/' ||
      location.pathname === '/patricia' ||
      location.pathname === '/contact' ||
      location.pathname === '/about'
    ) {
      setMenuWhite(true);
    } else {
      setMenuWhite(false);
    }
  }, [location.pathname]);

  return (
    <header className={menuWhite ? 'header-section white' : 'header-section'}>
      <div className="logo-box">
        <Link to="/" className="header-logo">
          <img className="logo" src={companyLogo} alt="company_logo" />
        </Link>
      </div>
      <div onClick={handleShow} className={show ? 'menu menu_active' : 'menu'}>
        Menu
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton onClick={handleClose} />
        <Offcanvas.Body>
          <nav>
            <ul className="nav-list">
              <li onClick={handleClose}>
                <Link to="/escribano">Escribano</Link>
              </li>
              <li onClick={handleClose}>
                <Link to="/patricia">Patricia</Link>
              </li>
              <li>
                <div
                  className="sub-nav-list-header"
                  onClick={handleShowProducts}
                >
                  <div>
                    Our Products
                    <BsFillCaretDownFill />
                  </div>
                  <ul
                    className={
                      showProducts ? 'sub-nav-list show' : 'sub-nav-list'
                    }
                  >
                    <li onClick={handleClose}>
                      <Link to="/visual-display-boards">Visual Display Boards</Link>
                    </li>
                    <li onClick={handleClose}>
                      <Link to="/cafeteria-tables-and-benches">Tables & Benches</Link>
                    </li>
                    <li onClick={handleClose}>
                      <Link to="/teaching-walls">Teaching Walls</Link>
                    </li>
                    <li onClick={handleClose}>
                      <Link to="/display-cases">Display Cases</Link>
                    </li>
                    <li onClick={handleClose}>
                      <Link to="/wall-systems">Wall Systems</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div
                  className={
                    showProducts ? 'translate' : 'translate translate-up'
                  }
                >
                  <div className="sub-nav-list-header ">
                    <div onClick={handleShowArchitect}>
                      Architects Corner
                      <BsFillCaretDownFill />
                    </div>
                    <ul
                      className={
                        showArchitect ? 'sub-nav-list show' : 'sub-nav-list'
                      }
                    >
                      <li onClick={handleClose}>
                        <Link to="/autocad-library">AutoCAD Library</Link>
                      </li>
                      <li onClick={handleClose}>
                        <Link to="/installation-instructions">
                          Installation Instructions
                        </Link>
                      </li>
                      <li onClick={handleClose}>
                        <Link to="/product-data">Product Data</Link>
                      </li>
                      <li onClick={handleClose}>
                        <Link to="/specifications">Specifications</Link>
                      </li>
                      <li onClick={handleClose}>
                        <Link to="/color-cards">Color Cards</Link>
                      </li>
                    </ul>
                  </div>
                  <ul
                    className={
                      showArchitect ? 'translate' : 'translate translate-up'
                    }
                  >
                    <li>
                      <Link to="/contact" onClick={handleClose}>
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={handleClose}>
                        About Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
};

export default Header;
