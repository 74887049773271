import React from 'react';
import styles from './ContactRow.module.css';
const style_title = {
    fontSize: '3.5em',
};
const style_center = {
    textAlign:'center',
};
const ContactRow = (props) => {
  return (
    <>
      <font style={style_title} className={styles.contact_sect_title}>{props.rowTitle}</font>
      <div  className={styles.contact_cards}>{props.children}</div>
    </>
  );
};

export default ContactRow;
