// Images
import lydiksen from '../../assets/images/AboutUsPage/lydiken.jpg';
import tokay from '../../assets/images/AboutUsPage/tokay.jpg';
import hinsdale from '../../assets/images/AboutUsPage/hinsdale.jpg';
import lomaVista from '../../assets/images/AboutUsPage/loma_vista.jpg';
import thurgood from '../../assets/images/AboutUsPage/thurgood_marshall.jpg';
import delOro from '../../assets/images/AboutUsPage/del_oro.jpg';
import rocky from '../../assets/images/AboutUsPage/rocky_elementray.jpg';
import northBergen from '../../assets/images/AboutUsPage/north_bergen.jpg';

const project = [
  {
    id: 1,
    title: `Lydiksen Elementary School`,
    location: 'Pleasanton, CA',
    image: lydiksen,
    alt: 'lydiksen_elementary_school',
  },
  {
    id: 2,
    title: `Tokay High School`,
    location: 'Lodi, CA',
    image: tokay,
    alt: 'tokay_high_school',
  },
  {
    id: 3,
    title: `Hinsdale South High School`,
    location: 'Aurora, IL',
    image: hinsdale,
    alt: 'hinsdale_south_high_school',
  },
  {
    id: 4,
    title: `Loma Vista Elementary School`,
    location: 'Hermiston, OR',
    image: lomaVista,
    alt: 'loma_vista_elementary_school',
  },
  {
    id: 5,
    title: `Thurgood Marshall High School`,
    location: 'San Francisco, CA',
    image: thurgood,
    alt: 'thurgood_marshall_high_school',
  },
  {
    id: 6,
    title: `Del Oro High School`,
    location: 'Bakersfield, CA',
    image: delOro,
    alt: 'del_oro_high_school',
  },
  {
    id: 7,
    title: `Rocky Elementary School`,
    location: 'Hermiston, OR',
    image: rocky,
    alt: 'rocky_elementary_school',
  },
  {
    id: 8,
    title: `North Bergen High School`,
    location: 'SECAUCUS, NJ',
    image: northBergen,
    alt: 'north_bergen_high_school',
  },
];

export { project };
