import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Redirect.module.css';

const Redirect = () => {
  return (
    <section className={styles.redirect_section}>
      <div className={styles.error_box}>
        <h1>404</h1>
        <h2>Page not found </h2>
        <p>Sorry the page you are looking for does not exist.</p>
        <Link to="/">
          <button type="button">Go home</button>
        </Link>
      </div>
    </section>
  );
};

export default Redirect;
