import React from 'react';
import styles from './Butterfly.module.css';
import { features } from './ButterflyItems';

import butterfly from '../../assets/images/Escribano/esc_butterfly_screen.png';

const Butterfly = () => {
  return (
    <section className={styles.butterfly_section}>
      <div className={styles.butterfly_body}>
        <div>
          <h1
            className={styles.butterfly_body_title}
            data-aos="fade-right"
            data-aos-delay="500"
          >
            Meet The Butterfly Menu
          </h1>
          <p
            className={styles.butterfly_body_description}
            data-aos="fade-right"
            data-aos-delay="700"
          >
            Our butterfly menu gives you access to all the tools you need to use
            Escribano and our displayed color pallet makes it easy to select the
            color pen you need.
          </p>
        </div>
        <div
          className={styles.butterfly_image_div}
          data-aos="fade-left"
          data-aos-delay="900"
        >
          <img src={butterfly} alt="escribano_butterfly_screen" />
        </div>
      </div>
      <div
        className={styles.feature_box}
        data-aos="fade-up"
        data-aos-delay="1000"
      >
        <div className={styles.feature_items}>
          {features.map((feat) => (
            <div key={feat.id} className={styles.feature_item}>
              <div className={styles.feature_icon}>
                <img src={feat.image} alt="feature_icon" />
              </div>
              <h4 className={styles.feature_title}>{feat.title}</h4>
              <p className={styles.feature_description}>{feat.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Butterfly;
