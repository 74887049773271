import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

import companyLogo from '../assets/images/company_logo_big.jpg';

const Footer = () => {
  return (
    <footer>
      <div className={styles.footer_title_box}>
        <div>
          <div className={styles.footer_company_logo_box}>
            <img src={companyLogo} alt="company-logo" />
          </div>
          <div className={styles.footer_company_title}>NELSON ADAMS NACO</div>
        </div>
      </div>

      <div className={styles.footer_address}>
        <div>420 South E St</div>
        <div>San Bernardino CA, 92401</div>
        <div>909-383-0819</div>
      </div>

      <ul className={styles.footer_links}>
        <li>
          <Link to="/escribano" className={styles.patricia_button}>
            Escribano
          </Link>
        </li>
        <li>
          <Link to="/visual-display-boards">Unique Visual concepts</Link>
        </li>
        <li>
          <Link to="/cafeteria-tables-and-benches">Tables And Benches</Link>
        </li>
        <li>
          <Link to="/wall-systems">Tackwall Systems</Link>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
